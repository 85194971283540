<template>
  <a-modal
    v-model="isShow"
    centered
    :mask-closable="false"
    title="事件批次信息"
  >
    <template slot="footer">
      <a-button class="ant-btn-primary" @click="closeModal">
        关闭
      </a-button>
    </template>

    <a-spin :spinning="loading">
      <component
        :is="currentComponent"
        :data="data"
      />
    </a-spin>
  </a-modal>
</template>

<script>
import { findAgentBalanceStatistic } from '@/api/balance_statistic'

export default {
  name: 'ShowBalanceStatistic',
  components: {
    BalanceStatisticAgentMonthlyBillInfo: () => import('@/views/balance_statistics/agent/info/AgentMonthlyBillInfo'),
    BalanceStatisticAgentTransferInfo: () => import('@/views/balance_statistics/agent/info/AgentTransferInfo')
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    billableType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    agentId() {
      return parseInt(this.$route.params.id)
    },

    currentComponent() {
      if (this.loading) {
        return ''
      }

      switch (this.billableType) {
        case 'transfer':
          return 'BalanceStatisticAgentTransferInfo'
        case 'agent_monthly_bill':
          return 'BalanceStatisticAgentMonthlyBillInfo'
        default:
          if (this.billableType !== '') {
            console.log(`请配置 ${this.billableType}`)
          }
          return ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    closeModal() {
      this.isShow = false
    },

    fetchData() {
      this.loading = true
      findAgentBalanceStatistic(this.id, { agent_id: this.agentId }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>
