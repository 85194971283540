import request from '@/utils/request'
// 查询客户
export function findAgents(params) {
  return request({
    url: `/agents`,
    method: 'get',
    params: params
  })
}

// 查询客户选项
export function findAgentOptions(params) {
  return request({
    url: `/agents/options`,
    method: 'get',
    params: params
  })
}

// 创建客户
export function createAgent(data) {
  return request({
    url: `/agents`,
    method: 'post',
    data
  })
}

// 查询单个客户表单值
export function findAgentForm(agentId) {
  return request({
    url: `/agents/${agentId}/form`,
    method: 'get'
  })
}

// 更新客户
export function updateAgent(agentId, data) {
  return request({
    url: `/agents/${agentId}`,
    method: 'put',
    data
  })
}

// 批量删除客户
export function deleteAgents(agentIds) {
  return request({
    url: `/agents/batch`,
    method: 'delete',
    data: agentIds
  })
}

// 批量更新客户有效性
export function batchUpdateAgentsEffective(ids, effective) {
  const data = { ids: ids, effective: effective }
  return request({
    url: `/agents/effective/batch`,
    method: 'patch',
    data
  })
}

// 查询单个客户信息
export function findAgent(agentId) {
  return request({
    url: `/agents/${agentId}`,
    method: 'get'
  })
}

// 查询客户名称
export function findAgentName(agentId) {
  return request({
    url: `/agents/${agentId}/name`,
    method: 'get'
  })
}

// 查询客户是否是topagent
export function isTopAgent(agentId) {
  return request({
    url: `/agents/${agentId}/is_top_agent`,
    method: 'get'
  })
}

// 查询客户有效性
export function findAgentEffective(agentId) {
  return request({
    url: `/agents/${agentId}/effective`,
    method: 'get'
  })
}

// 查询客户财务信息总览
export function findAgentFinanceOverview(agentId) {
  return request({
    url: `/agents/${agentId}/finance/overview`,
    method: 'get'
  })
}

// 查询客户API信息
export function findAgentApiInfo(agentId) {
  return request({
    url: `/agents/${agentId}/api_info`,
    method: 'get'
  })
}

// 更新API权限是否开启
export function updateAgentIsApiEnabled(agentId, data) {
  return request({
    url: `/agents/${agentId}/is_api_enabled`,
    method: 'patch',
    data
  })
}

// API信息表单数据
export function findAgentApiInfoForm(agentId) {
  return request({
    url: `/agents/${agentId}/api_info_form`,
    method: 'get'
  })
}

// 更新客户API信息
export function updateAgentApiInfo(agentId, data) {
  return request({
    url: `/agents/${agentId}/api_info`,
    method: 'put',
    data
  })
}

// 查询客户账户设置
export function findAgentSettings(agentId) {
  return request({
    url: `/agents/${agentId}/settings`,
    method: 'get'
  })
}

// 更新账户相关设置
export function UpdateAgentSetting(agentId, data) {
  return request({
    url: `/agents/${agentId}/setting`,
    method: 'patch',
    data
  })
}

// 查询企业客户数量下的客户
export function findAgentAgenciesOverview(agentId, params) {
  return request({
    url: `/agents/${agentId}/agencies/overview`,
    method: 'get',
    params: params
  })
}

// 查询销售管理下客户列表的企业客户列表
export function findAgencies(agentId, params) {
  return request({
    url: `/agents/${agentId}/agencies`,
    method: 'get',
    params: params
  })
}

// 导出销售管理下客户列表的企业客户列表
export function exportAgencies(agentId, data) {
  return request({
    url: `/agents/${agentId}/agencies/export`,
    method: 'post',
    data
  })
}

// 导出客户
export function exportAgents(data) {
  return request({
    url: `/agents/export`,
    method: 'post',
    data
  })
}

// 更新是否开启
export function updateAgentIsAfterPayEnabled(agentId, data) {
  return request({
    url: `/agents/${agentId}/is_after_pay_enabled`,
    method: 'patch',
    data
  })
}

// 查询企业客户API信息
export function findAgencyApiInfoForm(agentId) {
  return request({
    url: `/agencies/${agentId}/api_info_form`,
    method: 'get'
  })
}
